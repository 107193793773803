<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="el-main a-fs-14 projectadd">
      <div class="fnW600 font18 mgb36">员工信息</div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        :inline="false"
        size="mini"
        label-position="left"
      >
        <el-form-item label="姓名" prop="userName">
          <el-input
            v-model="form.userName"
            class="w300"
            maxlength="10"
            placeholder="请输入员工姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input
            v-model="form.mobile"
            maxlength="11"
            class="w300"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            maxlength="20"
            class="w300"
            placeholder="请设置密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="标签" prop="remark">
          <el-input
            v-model="form.remark"
            class="w300"
            maxlength="20"
            placeholder="请输入员工标签"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属商户" prop="companyId">
          <le-company-under-select
            v-model="form.companyId"
            class="companyClass"
            @input="companyBack"
          ></le-company-under-select>
        </el-form-item>
        <el-form-item label="所在部门" prop="dep">
          <el-select v-model="form.dep" placeholder="请选择员工类型">
            <el-option
              v-for="(item, index) in depList"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="过期时间" prop="disableTime">
          <el-date-picker
            v-model="form.disableTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="账号状态" prop="isValid">
          <el-switch v-model="form.isValid" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="登录平台" prop="platform">
          <el-checkbox-group v-model="platform" @change="changePlatform">
            <el-checkbox label="1">WEB端</el-checkbox>
            <el-checkbox label="2">微信小程序端</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div v-if="hasWeb" class="pdb30">
          <rolecheck
            title="WEB端权限分配"
            type="web"
            :list="roleData.webList"
            :company-id="form.companyId"
            @input="getWebData"
          ></rolecheck>
        </div>
        <div v-if="hasWx" class="pdb30">
          <rolecheck
            title="微信小程序端权限分配"
            type="wx"
            :list="roleData.wxList"
            :company-id="form.companyId"
            @input="getWxData"
          ></rolecheck>
        </div>
        <div class="flex_start">
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button @click="$router.back()">&nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import validate from "../../utils/validate";
import Rolecheck from "./child/role-check.vue"; //员工列表
export default {
  name: "StaffAdd",

  components: {
    Rolecheck,
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("用户姓名不能为空"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!validate.isvalidPhone(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    const validateCompany = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择所属商户"));
      } else {
        callback();
      }
    };
    return {
      form: {
        id: null, //更新时传
        userName: "", //用户姓名
        mobile: "", //电话
        password: "",
        companyId: null,
        dep: "",
        remark: "", //标签
        disableTime: "",
        isValid: 0,
        canLoginWxApp: 0,
        canLoginWeb: 0,
        roleIds: [],
        userId: null,
      },
      rules: {
        userName: [
          {
            validator: validateName,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        companyId: [
          {
            validator: validateCompany,
            trigger: "change",
          },
        ],
      },
      platform: [],
      roleData: {
        webList: [],
        wxList: [],
      },
      webList: [],
      wxList: [],
      depList: [],
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
    hasWeb() {
      const hasData = this.platform.find((val) => val == 1) && true;
      return hasData;
    },
    hasWx() {
      const hasData = this.platform.find((val) => val == 2) && true;
      return hasData;
    },
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  created() {
    if (this.$route.query.id) {
      this.form.id = parseInt(this.$route.query.id);
      this.form.userId = parseInt(this.$route.query.userId);
      this.form.companyId = parseInt(this.$route.query.companyId);
      this.getDetail();
    } else {
      this.form.companyId = this.company.id;
    }
    this.getDepList();
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.platform.length) {
            this.form.canLoginWeb = this.platform.find((val) => val == 1) ? 1 : 0;
            this.form.canLoginWxApp = this.platform.find((val) => val == 2) ? 1 : 0;
          }
          const conArr = [];
          if (this.webList.length) {
            for (let i = 0; i < this.webList.length; i++) {
              const element = this.webList[i];
              conArr.push(element.id);
            }
          }
          if (this.wxList.length) {
            for (let i = 0; i < this.wxList.length; i++) {
              const element = this.wxList[i];
              conArr.push(element.id);
            }
          }
          this.form.roleIds = conArr;
          this.$Axios
            ._post({
              url: this.$Config.apiUrl.saveRbacUser,
              params: this.form,
            })
            .then(() => {
              this.$message.success("操作成功!");
              this.$router.back();
              this.$store.dispatch("delView", this.$route);
            });
        }
      });
    },
    //选中web权限
    getWebData(data) {
      this.webList = data;
    },
    //选中wx权限
    getWxData(data) {
      this.wxList = data;
    },
    //平台变化
    changePlatform(e) {
      if (!this.form.companyId) {
        this.platform = [];
        return;
      }
      const hasData = e.find((val) => val == 1) && true;
      if (!hasData) {
        this.webList = [];
      }
      const hasData2 = e.find((val) => val == 2) && true;
      if (!hasData2) {
        this.wxList = [];
      }
    },
    //获取详情
    getDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getCompanyUserDetail,
          params: {
            userId: this.form.userId,
            companyId: this.form.companyId,
          },
        })
        .then((res) => {
          this.form = {
            ...res.result.data,
          };
          if (res.result.data.canLoginWeb == 1) {
            this.platform.push("1");
            this.getWebModuleUserRole();
          }
          if (res.result.data.canLoginWxapp == 1) {
            this.platform.push("2");
            this.getWxAppModuleUserRole();
          }
        });
    },
    //获取用户web权限
    getWebModuleUserRole() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getWebModuleUserRole,
          params: {
            userId: this.form.userId,
            companyId: this.form.companyId,
          },
        })
        .then(({ data }) => {
          const p_arr = data.filter((item) => item.userHasThisRole);
          if (p_arr.length) {
            const n_arr = p_arr.map((item) => (item.userHasThisRole ? item.roleId : ""));
            this.roleData.webList = n_arr;
          }
        });
    },
    //获取用户wx权限
    getWxAppModuleUserRole() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getWxAppModuleUserRole,
          params: {
            userId: this.form.userId,
            companyId: this.form.companyId,
          },
        })
        .then(({ data }) => {
          const p_arr = data.filter((item) => item.userHasThisRole);
          if (p_arr.length) {
            const n_arr = p_arr.map((item) => (item.userHasThisRole ? item.roleId : ""));
            this.roleData.wxList = n_arr;
          }
        });
    },
    getDepList() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getDepList,
        })
        .then(({ data }) => {
          this.depList = data;
        });
    },
    companyBack() {
      this.platform = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.companyClass {
  padding: 0 !important;

  /deep/ .a-ml-20 {
    margin-left: 0;
  }
}
</style>
